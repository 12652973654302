import { ExperimentPage } from '~/cf-worker/types';

export const experimentedPages: ExperimentPage[] = [
  {
    name: 'Azure Gen AI Engineer Homepage Takeover',
    experimentKey: 'homepage_takeover',
    pathRegex: /^\/$/,
  },
  {
    name: 'Checkout Box AB Test',
    experimentKey: 'ab_checkout_value_props', // https://app.amplitude.com/experiment/udacityPOC/218461/config/334814/settings
    pathRegex: /^\/checkout\/individual\/(4month|monthly)\/(credit-card|saved-card|razorpay)$/,
  },
  {
    experimentKey: 'ab_pd_coupon_page_redesign',
    name: 'PD Coupon Success Page Redesign AB Test',
    pathRegex: /^\/personalized-discount-success/,
  },
];
